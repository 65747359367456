import { apiAction } from '../../helpers/apiMiddleware/actions';
import { getConfig } from '../configuration/actions';
import { URL } from '../../helpers/config';
import { failed } from '../actions'

const URL_PREFIX = `${URL}/shutters`;

export const CREATED_CONFIG = 'CREATED_CONFIG';
export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const EDIT_CONFIG = 'EDIT_CONFIG';
export const FAILED = 'FAILED_EDITOR';
export const SUCCESS = 'SUCCESS_EDITOR';

export const createConfig = (config) => {
    return apiAction({
        url: URL_PREFIX,
        label: CREATED_CONFIG,
        data: config,
        accessToken: localStorage.getItem('accesToken'),
        method: 'POST',
        onSuccess: getConfig,
        onFailure: failed
    })
}

export const updateConfig = (id, config) => {
    return apiAction({
        url: `${URL_PREFIX}/${id}`,
        label: UPDATE_CONFIG,
        data: config,
        accessToken: localStorage.getItem('accesToken'),
        method: 'PUT',
        onSuccess: getConfig,
        onFailure: failed
    })
}

export const editConfig = (config) => {
    return { config, type: EDIT_CONFIG }
}
