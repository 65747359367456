import { apiAction } from '../../helpers/apiMiddleware/actions';
import { URL } from '../../helpers/config';
import { failed } from '../actions'

const URL_PREFIX = `${URL}/shutters`;

export const GET_CONFIG = 'GET_CONFIG';
export const DELETE_CONFIG = 'DELETE_CONFIG';
export const SUCCESS = 'SUCCESS_CONFIGURATION';

export const getConfig = () => {
    return apiAction({
        url: URL_PREFIX,
        label: GET_CONFIG,
        accessToken: localStorage.getItem('accesToken'),
        method: 'GET',
        onSuccess: success,
        onFailure: failed
    });
}

export const deleteConfig = (id) => {
    return apiAction({
        url: `${URL_PREFIX}/${id}`,
        label: DELETE_CONFIG,
        accessToken: localStorage.getItem('accesToken'),
        method: 'DELETE',
        onSuccess: getConfig,
        onFailure: failed
    });
}

const success = (data) => {
    return {
        data,
        type: SUCCESS
    }
}
