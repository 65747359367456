import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux"
import {makeStyles} from '@material-ui/core/styles'
import {deleteConfig, getConfig} from './actions'
import {close, open} from '../actions'
import {editConfig} from '../editor/actions'
import {IconButton, List} from '@material-ui/core'
import {ArrowDownward, ArrowUpward, ChevronLeft, ChevronRight, Delete, Edit} from '@material-ui/icons'
import CircularProgress from '@material-ui/core/CircularProgress'
import {blue, green} from '@material-ui/core/colors'
import Paper from "@material-ui/core/Paper";
import shutterIcon from "../../assets/shutters.svg";
import Typography from "@material-ui/core/Typography";
import history from '../../helpers/history';


const useStyles = makeStyles((theme) => ({
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    openButtonProgress: {
        color: blue[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    closeButtonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    root: {
        display: "flex",
        flexWrap: "wrap",
        "& > *": {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
        },
    },
    shutter: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
        height: '100%',
        maxWidth: 'xs'
    },
    shutterLabel: {
        fontSize: "1.2rem",
        fontWeight: "bold",
        marginBottom: theme.spacing(2),
    },
    shutterButtons: {
        display: "flex",
        justifyContent: "space-between",
    },
    delay: {
        fontSize: '0.8rem',
        marginTop: 15,
    },
}))

export const ShutterConfiguration = () => {

    const classes = useStyles()

    const dispatch = useDispatch()

    const [index, setIndex] = React.useState(0);

    const {data, isLoading} = useSelector(state => {
        return {
            data: state.shutterConfig.data,
            isLoading: state.shutters.isLoading,
            error: state.shutterConfig.error
        }
    });

    useEffect(() => {
        dispatch(getConfig())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const upClick = (shutter) => {
        if (shutter.active) {
            const escapedDelay = shutter.delay || 0
            if (shutter.isReversed) {
                dispatch(close(shutter.id, escapedDelay))
            } else {
                dispatch(open(shutter.id, escapedDelay));
            }
        }
    }

    const downClick = (shutter) => {
        if (shutter.active) {
            if (shutter.isReversed) {
                dispatch(open(shutter.id))
            } else {
                dispatch(close(shutter.id));
            }
        }
    }

    const callDeleteConfig = () => {
        dispatch(deleteConfig(data[index].id))
        setIndex(0)
    }

    const getCurrentConfig = () => {
        const config = data[index];
        if (config !== undefined) {
            return (
                <>
                    <div className={classes.root}>
                        {config.rooms.sort((a, b) => a.id - b.id).map((shutter) => (
                            <Paper elevation={3} className={classes.shutter} key={shutter._id}>
                                <div>
                                    <h2 className={classes.shutterLabel}>{shutter.label}</h2>
                                </div>
                                <div>
                                    <img src={shutterIcon} alt=""
                                         onClick={() => history.push(`shutters/${config.id}/${shutter.id}`)}/>
                                </div>
                                <div className={classes.shutterButtons}>
                                    <IconButton aria-label="increase delay" onClick={() => upClick(shutter)}>
                                        <ArrowUpward color={shutter.active ? 'primary' : 'disabled'}/>
                                        {isLoading &&
                                            <CircularProgress size={24} className={classes.closeButtonProgress}/>}
                                    </IconButton>
                                    {shutter.delay > 0 && (
                                        <Typography variant="caption" align="center" className={classes.delay}>
                                            {shutter.delay}
                                        </Typography>
                                    )
                                    }
                                    <IconButton aria-label="decrease delay" onClick={() => downClick(shutter)}>
                                        <ArrowDownward color={shutter.active ? 'secondary' : 'disabled'}/>
                                        {isLoading &&
                                            <CircularProgress size={24} className={classes.closeButtonProgress}/>}
                                    </IconButton>
                                </div>
                            </Paper>
                        ))}
                    </div>
                </>
            )
        }
        return (<div>No configuration</div>)
    }

    const getPagination = () => {
        if (data.length > 0) {
            return (
                <div>
                    <IconButton color='primary'
                                onClick={() => setIndex(index - 1)}
                                disabled={index === 0}
                    >
                        <ChevronLeft/>
                    </IconButton>
                    <IconButton color='primary'
                                onClick={() => dispatch(editConfig(data[index]))}
                    >
                        <Edit/>
                    </IconButton>
                    <IconButton color='primary'
                                onClick={callDeleteConfig}
                    >
                        <Delete/>
                    </IconButton>
                    <IconButton color='primary'
                                onClick={() => setIndex(index + 1)}
                                disabled={index === data.length - 1}
                    >
                        <ChevronRight/>
                    </IconButton>
                </div>)
        }
    }

    return (
        <div>
            <List>
                {getCurrentConfig()}
            </List>
            {getPagination()}
        </div>
    )
}
