export const URL = 'api';

export const rooms = [
    {
        key: 'all',
        label: 'All',
        active: true,
        id: 0,
    },
    {
        key: 'tv',
        label: 'TV corner',
        active: true,
        id: 1,
    },
    {
        key: 'kitchen',
        label: 'Kitchen',
        active: true,
        id: 2,
    },
    {
        key: 'hallway',
        label: 'Hallway',
        active: true,
        id: 3,
    },
    {
        key: 'room',
        label: 'Living room',
        active: true,
        id: 5,
    },
    {
        key: 'office',
        label: 'Office',
        active: true,
        id: 4,
    },
    {
        key: 'room3',
        label: 'Leo',
        active: true,
        id: 6,
    },
    {
        key: 'room2',
        label: 'Sarah',
        active: true,
        id: 7,
    },
    {
        key: 'room1',
        label: 'Parent',
        active: true,
        id: 8,
        
    },
];
