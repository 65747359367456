
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux"
import { createConfig, updateConfig } from './actions'
import { JsonEditor as Editor } from 'jsoneditor-react'
import 'jsoneditor-react/es/editor.min.css'
import Button from '@material-ui/core/Button'
import { getConfig } from '../configuration/actions'

export const ShutterEditor = () => {

    const dispatch = useDispatch();
    const buttonRef = React.useRef()
    let editor
    let { shutterConfig, dataError } = useSelector(state => {
        return {
            shutterConfig: state.shutterEditor.config,
            dataError: state.shutterEditor.dataError
        }
    })
    const [buttonLabel, setButtonLabel] = React.useState('Add configuration')

    useEffect(() => {
        updateDisplay()
    }, [shutterConfig])

    const setEditorRef = instance => {
        if (instance) {
            const { jsonEditor } = instance
            editor = jsonEditor
        }
    }

    const updateDisplay = () => {
        editor.update(shutterConfig)
        shutterConfig.id ? setButtonLabel("Update configuration") : setButtonLabel("Add configuration")
    }

    const createConfigAction = () => {
        if (editor.get().id) {
            dispatch(updateConfig(editor.get().id, editor.get()))
        } else {
            dispatch(createConfig(editor.get()))
        }
        dispatch(getConfig())
    }

    const onChange = (change) => {
        shutterConfig = change
        shutterConfig.id ? setButtonLabel("Update configuration") : setButtonLabel("Add configuration")
    }

    return (
        <div>
            <Editor ref={setEditorRef} value={shutterConfig} onChange={onChange} />
            <Button ref={buttonRef} variant="contained" color="primary" onClick={createConfigAction}>
                {buttonLabel}
            </Button>
            <div>{dataError.message}</div>
        </div>
    )
}