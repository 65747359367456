import React from 'react';
import './App.css';
import {Route, Switch,} from "react-router-dom";
import {refreshToken} from './login/actions'
import {useDispatch} from "react-redux";
import {Login} from './login/login'
import {Loading} from './loading/Loading'
import {Shutters} from './shutters/shutters'
import {Temperatures} from './temperatures/temperature'
import {Profile} from './profile/Profile'
import {Logout} from './logout/Logout'
import history from "./helpers/history";
import {ShutterDetails} from "./shutters/details/ShutterDetails";

function App() {

    const dispatch = useDispatch();
    if (localStorage.getItem('refreshToken')) {
        dispatch(refreshToken())
    } else {
        history.push('/login')
    }

    return (
        <Switch>
            <Route exact path="/" component={Loading}/>
            <Route exact path="/login" component={Login}/>
            <Route exact path="/app" component={Shutters}/>
            <Route exact path="/shutters" component={Shutters}/>
            <Route exact path="/shutters/:configId/:id" component={ShutterDetails}/>
            <Route exact path="/temperatures" component={Temperatures}/>
            <Route exact path="/profile" component={Profile}/>
            <Route exact path="/logout" component={Logout}/>
            <Route exact path="/*" component={Shutters}/>
        </Switch>
    );
}

export default App;
