import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch, useSelector} from "react-redux"
import {AppMenu} from '../helpers/components/AppMenu'
import {getData, getAllData} from './actions'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import {
    ArgumentAxis,
    ValueAxis,
    Chart,
    LineSeries,
} from '@devexpress/dx-react-chart-material-ui';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(5),
        },
    },
}));

const getDateArgument = (timestamp) => {
    const date = new Date(timestamp * 1000)
    return `${date.getHours()}`
}

export const Temperatures = () => {
    const classes = useStyles();

    const {data, allData, isLoading, error} = useSelector(state => {
        return {
            data: state.rooms.data,
            allData: state.rooms.allData,
            isLoading: state.rooms.isLoading,
            error: state.rooms.error
        }
    });
    const dispatch = useDispatch();

    const displayHumidityGraph = () => {
        return displayGraph('humidity', 'humidityUnit', 'Humidity', allData)
    }

    const displayTemperatureGraph = () => {
        return displayGraph('temperature', 'temperatureUnit', 'Temperature', allData)
    }

    const displayGraph = (key, unit, name, allData) => {
        return (
            <div>
                <ListItem key={{key}}>
                    <ListItemText primary={`${name} : ${data[key]} ${data[unit]}`}/>
                </ListItem>
                < Paper>
                    <Chart
                        data={allData.map(data => ({argument: getDateArgument(data.timestamp), value: data[key]}))}
                    >
                        <ArgumentAxis/>
                        <ValueAxis/>

                        <LineSeries valueField="value" argumentField="argument"/>
                    </Chart>
                </Paper>
            </div>
        )
    }

    useEffect(() => {
        dispatch(getData(1))
        dispatch(getAllData())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <AppMenu>
            <div>Room 1</div>
            <List>
                {isLoading &&
                    <div className={classes.root}>
                        <CircularProgress/>
                    </div>
                }
                {!isLoading && data && allData[0] &&
                    <>
                        <div>{displayTemperatureGraph()}</div>
                        <div>{displayHumidityGraph()}</div>
                    </>
                }
                {error &&
                    <ListItem key="error">
                        <ListItemText primary={error}/>
                    </ListItem>

                }
            </List>
        </AppMenu>
    )
}