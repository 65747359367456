import {CREATED_CONFIG, EDIT_CONFIG, UPDATE_CONFIG} from './actions'

const defaultConfig = {
    "userIds": [localStorage.getItem('username')],
    "rooms": [
        {
            "label": "room_name",
            "active": true,
            "id": 0,
            "delay": 0,
            "isReversed": false,
        },
    ]
}

export const shutterEditorReducer = (state = { error: false, data: {}, dataError: {}, config: defaultConfig }, action) => {
    switch (action.type) {
        case UPDATE_CONFIG:
            return {
                ...state,
            }
        case CREATED_CONFIG:
            return {
                ...state,
                config: defaultConfig,
            }
        case EDIT_CONFIG:
            return {
                ...state,
                config: action.config,
            }
        default:
            return state;
    }
}