import { API_START } from '../../helpers/apiMiddleware/actions';
import { GET_CONFIG, SUCCESS } from './actions';

export const shutterConfigReducer = (state = { error: false, data: [], dataError: {} }, action) => {
    switch (action.type) {
        case API_START:
            if (action.payload === GET_CONFIG) {
                return {
                    ...state,
                    isLoading: true,
                    data: []
                };
            }
            return state;
        case SUCCESS:
            return {
                ...state,
                data: action.data,
                isLoading: false
            }
        default:
            return state;
    }
}