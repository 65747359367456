import React from 'react'
import {useDispatch, useSelector} from "react-redux"
import {clearError} from './actions'
import {AppMenu} from '../helpers/components/AppMenu'
import {IconButton, Collapse} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { ShutterConfiguration } from './configuration/ShutterConfiguration'
import { ShutterEditor } from './editor/ShutterEditor'


const useStyles = makeStyles((theme) => ({
    wrap: {
        maxWidth: '160px'
    },
}))

export const Shutters = () => {

    const classes = useStyles();

    const {dataError, error} = useSelector(state => {
        return {
            dataError: state.shutters.dataError,
            error: state.shutters.error
        }
    });
    const dispatch = useDispatch();

    const [showEditor, setShowEditor] = React.useState(false);

    return (
        <AppMenu>
            <Collapse in={error}>
                <Alert severity="error"
                       action={
                           <IconButton
                               aria-label="close"
                               color="inherit"
                               size="small"
                               onClick={() => {
                                   dispatch(clearError())
                               }}
                           >
                               <CloseIcon fontSize="inherit"/>
                           </IconButton>
                       }
                >
                    <span className={classes.wrap}>{`${dataError.status} : ${dataError.message}`}</span>
                </Alert>
            </Collapse>
            <ShutterConfiguration/>
            <IconButton
                aria-label="expand"
                color="primary"
                size="medium"
                onClick={() => {
                    setShowEditor(!showEditor)
                }}
            >
                <KeyboardArrowDown fontSize="inherit"/>
            </IconButton>
            <Collapse in={showEditor}>
                <ShutterEditor/>
            </Collapse>
        </AppMenu>
    )
}