import React from 'react';
import {useDispatch, useSelector} from "react-redux"
import {makeStyles} from '@material-ui/core/styles'
import {useParams} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {AppMenu} from "../../helpers/components/AppMenu";
import history from '../../helpers/history';
import {close, open} from "../actions";
import {getConfig} from "../configuration/actions";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

export const ShutterDetails = () => {

    const {configId, id} = useParams()

    const dispatch = useDispatch()

    const {shutter} = useSelector(state => {
        return {
            shutter: state.shutterConfig.data.find(config => config.id === configId)?.rooms?.find(shutter => shutter.id === Number(id)) || undefined,
            isLoading: state.shutters.isLoading,
            error: state.shutterConfig.error
        }
    });

    const classes = useStyles();

    const handleOpen = () => {
        if (shutter.isReversed) {
            dispatch(close(shutter.id,))
        } else {
            dispatch(open(shutter.id));
        }
        dispatch(getConfig())
    };
    const handleClose = () => {
        if (shutter.isReversed) {
            dispatch(open(shutter.id))
        } else {
            dispatch(close(shutter.id));
        }
        dispatch(getConfig())
    }

    function redirectToHome() {
        if (shutter === undefined) {
            history.push("/")
        }
    }

    return (
        <AppMenu>
            {redirectToHome}
            {shutter &&
                <div className={classes.root}>
                    <h2>{shutter.label}</h2>
                    <Button variant="contained" color="primary" onClick={handleOpen}>Ouvrir</Button>
                    <Button variant="contained" color="secondary" onClick={handleClose}>Fermer</Button>
                </div>
            }
        </AppMenu>
    );
}
